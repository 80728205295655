<template>
  <div id="ProductOptions">
    <CCard>
      <CCardBody class="p-2 bg-light">
        <CCardText>
          <CButton color="success" class="mr-2" @click="FieldGenerateModel = true">
            {{ (OptionData.length > 0 ? $t('Global.Redo') : '') + $t('Product/Options.GenerateOptions') }}
          </CButton>
          <CButton v-if="OptionField.length > 0" color="info" class="mr-2" @click="FieldEditModel = true">
            {{ $t('Global.Edit') + $t('Product/Options.Field') }}
          </CButton>
          <CButton v-if="OptionData.length > 0" color="warning" class="mr-2" @click="EditOptionValueModel = true">
            {{ $t('Product/Options.EditOptionValue') }}
          </CButton>
          <CButton v-if="ChooseKeys.length > 0" color="info" class="mr-2" @click="BulkEditOptionModel = true">
            {{ $t('Product/Options.BulkEditOptions') }}
          </CButton>
          <CButton color="secondary" @click="ResetOptions()">
            {{ $t('Product/Options.ResetOptions') }}
          </CButton>
        </CCardText>
      </CCardBody>
    </CCard>
    <CDataTable
        :items="OptionData"
        :fields="Field"
        :noItemsView="noItemsView"
        responsive
        class="text-center"
        hover>
      <template #Checkbox-header>
        <label class="mb-0">
          <input type="checkbox" id="CheckAll" v-model="CheckAllKeys" @change="CheckAll()">
        </label>
      </template>
      <template v-for="(FieldData, Index) in Field" v-slot:[FieldData.key]="{item}">
        <td v-if="!DefaultOptionFields.includes(FieldData.key)" :key="Index" class="EditColumn">
          <span>{{ item[FieldData.key] }}</span>
        </td>
      </template>
      <template #Checkbox="{item, index}">
        <td class="text-center">
          <label class="mb-0">
            <input type="checkbox" :id="'Checkbox-Option-' + item._id" :value="index" v-model="ChooseKeys">
          </label>
        </td>
      </template>
      <template #CostAmount="{item, index}">
        <td class="EditColumn">
          <CInput
              v-if="EditingOptionIndex === index"
              :prepend="$store.state.currency + '$'"
              v-model="item.Amount.Cost"
              type="number"
              size="sm"
          />
          <span v-else>{{ $store.state.currency + '$' }}{{ item.Amount.Cost }}</span>
        </td>
      </template>
      <template #SuggestAmount="{item, index}">
        <td class="EditColumn">
          <CInput
              v-if="EditingOptionIndex === index"
              :prepend="$store.state.currency + '$'"
              v-model="item.Amount.Suggest"
              type="number"
              size="sm"
          />
          <span v-else>{{ $store.state.currency + '$' }}{{ item.Amount.Suggest }}</span>
        </td>
      </template>
      <template #ActualAmount="{item, index}">
        <td class="EditColumn">
          <CInput
              v-if="EditingOptionIndex === index"
              :prepend="$store.state.currency + '$'"
              v-model="item.Amount.Actual"
              type="number"
              size="sm"
          />
          <span v-else>{{ $store.state.currency + '$' }}{{ item.Amount.Actual }}</span>
        </td>
      </template>
      <template #SKU="{item, index}">
        <td class="EditColumn">
          <CSelect
            size="sm"
            v-if="EditingOptionIndex === index"
            :options="SKUList"
            v-model="item.Stock.CurrentSKU"
            :value.sync="item.Stock.CurrentSKU"
            :placeholder="$t('Global.PleaseSelect')"
            @update:value="(selected) => item.Stock.SKU = selected"
          />
          <span v-else>{{ item.Stock.SKU }}</span>
        </td>
      </template>
      <template #MaxStock="{item, index}">
        <td class="EditColumn">
          <CInput
              v-if="EditingOptionIndex === index"
              v-model="item.Stock.MaxStock"
              type="number"
              size="sm"
          />
          <span v-else>{{ item.Stock.MaxStock }}</span>
        </td>
      </template>
      <template #Weight="{item, index}">
        <td class="EditColumn">
          <CInput
            v-if="EditingOptionIndex === index"
            v-model="item.Variable.Weight"
            type="number"
            size="sm"
          />
          <span v-else>{{ item.Variable.Weight }}</span>
        </td>
      </template>
      <template #NetWeight="{item, index}">
        <td class="EditColumn">
          <CInput
            v-if="EditingOptionIndex === index"
            v-model="item.Variable.NetWeight"
            type="number"
            size="sm"
          />
          <span v-else>{{ item.Variable.NetWeight }}</span>
        </td>
      </template>
      <template #Length="{item, index}">
        <td class="EditColumn">
          <CInput
            v-if="EditingOptionIndex === index"
            v-model="item.Variable.Length"
            type="number"
            size="sm"
          />
          <span v-else>{{ item.Variable.Length }}</span>
        </td>
      </template>
      <template #Width="{item, index}">
        <td class="EditColumn">
          <CInput
            v-if="EditingOptionIndex === index"
            v-model="item.Variable.Width"
            type="number"
            size="sm"
          />
          <span v-else>{{ item.Variable.Width }}</span>
        </td>
      </template>
      <template #Height="{item, index}">
        <td class="EditColumn">
          <CInput
            v-if="EditingOptionIndex === index"
            v-model="item.Variable.Height"
            type="number"
            size="sm"
          />
          <span v-else>{{ item.Variable.Height }}</span>
        </td>
      </template>
      <template #Action="{index}">
        <td class="EditColumn">
          <CButton :color="(EditingOptionIndex === index ? 'success' : 'info')" type="button" size="sm" class="mr-2" @click="EditOptions(index)">
            <CIcon v-if="EditingOptionIndex === index" name="cil-check-circle" class="c-icon-custom-size" />
            <CIcon v-else name="cil-pencil" class="c-icon-custom-size" />
          </CButton>
          <CButton color="danger" type="button" size="sm" @click="RemoveOptions(index)">
            <CIcon name="cil-trash" class="c-icon-custom-size" />
          </CButton>
        </td>
      </template>
    </CDataTable>
    <CModal id="FieldGenerateModel" size="lg" :show.sync="FieldGenerateModel" :centered="true">
      <div class="px-3">
        <CRow class="pb-3 font-weight-bold border-bottom">
          <CCol class="d-flex align-items-center" col="3">{{ $t('Product/Options.Field') }}</CCol>
          <CCol class="d-flex align-items-center" col="1">{{ $t('Product/Options.Level') }}</CCol>
          <CCol class="d-flex align-items-center" col="6">{{ $t('Product/Options.Value') }}</CCol>
          <CCol class="d-flex align-items-center" col="2" />
        </CRow>
        <template v-for="(field, index) in OptionField">
          <CRow :key="index" class="py-2 border-bottom">
            <CCol class="d-flex align-items-center" col="3">
              <CInput v-model="field.FieldName" class="mb-0" />
            </CCol>
            <CCol class="d-flex align-items-center" col="1">{{ field.Level }}</CCol>
            <CCol class="d-flex align-items-center" col="6">
              <multiselect
                  v-model="field.Value"
                  :id="index"
                  :tag-placeholder="$t('Global.EnterToAdd')"
                  :placeholder="$t('Global.PleaseKeyIn')"
                  :deselectLabel="$t('Global.DeselectLabel')"
                  :selectedLabel="$t('Global.SelectedLabel')"
                  :options="field.Value"
                  :multiple="true"
                  :taggable="true"
                  @tag="AddTag">
                <template #noOptions>
                  {{ $t('Global.NoOptions') }}
                </template>
              </multiselect>
            </CCol>
            <CCol class="d-flex align-items-center" col="2">
              <CButton color="danger" type="button" size="sm" @click="RemoveField(index)">
                <CIcon name="cil-trash" class="c-icon-custom-size" />
              </CButton>
            </CCol>
          </CRow>
        </template>
        <div class="text-left mt-3">
          <CButton color="info" type="button" @click="AddField()">
            {{ $t('Product/Options.AddField') }}
          </CButton>
        </div>
      </div>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Product/Options.GenerateOptions') }}</h5>
          <CButtonClose @click="FieldGenerateModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FieldGenerateModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FieldGenerateSubmit" @click="GenerateField()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FieldEditModel" :show.sync="FieldEditModel" :centered="true">
      <div class="px-3">
        <CRow class="pb-3 font-weight-bold border-bottom">
          <CCol class="d-flex align-items-center" col="2">{{ $t('Product/Options.Level') }}</CCol>
          <CCol class="d-flex align-items-center" col="10">{{ $t('Product/Options.Field') }}</CCol>
        </CRow>
        <template v-for="(field, index) in OptionField">
          <CRow :key="index" class="py-2 border-bottom">
            <CCol class="d-flex align-items-center" col="2">{{ field.Level }}</CCol>
            <CCol class="d-flex align-items-center" col="10">
              <CInput v-model="field.FieldName" class="mb-0 w-100" />
            </CCol>
          </CRow>
        </template>
      </div>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Edit') + $t('Product/Options.Field') }}</h5>
          <CButtonClose @click="FieldEditModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton id="FieldGenerateSubmit" @click="FieldEditModel = false" color="success">
            {{ $t('Global.Finish') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="BulkEditOptionModel" :show.sync="BulkEditOptionModel" :centered="true">
      <CInput
          :prepend="$store.state.currency + '$'"
          :label="$t('Product/Options.Amount/Cost')"
          v-model="BulkEditOptionData.Amount.Cost"
          type="number"
          horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
          :prepend="$store.state.currency + '$'"
          :label="$t('Product/Options.Amount/Suggest')"
          v-model="BulkEditOptionData.Amount.Suggest"
          type="number"
          horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
          :prepend="$store.state.currency + '$'"
          :label="$t('Product/Options.Amount/Actual')"
          v-model="BulkEditOptionData.Amount.Actual"
          type="number"
          horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
          :label="$t('Product/Options.Stock/RealStock')"
          v-model="BulkEditOptionData.Stock.RealStock"
          type="number"
          horizontal>
        <template #prepend-content>
          <CIcon name="cil-layers" />
        </template>
      </CInput>
      <CInput
          :label="$t('Product/Options.Stock/SafeStock')"
          v-model="BulkEditOptionData.Stock.SafeStock"
          type="number"
          horizontal>
        <template #prepend-content>
          <CIcon name="cil-layers" />
        </template>
      </CInput>
      <CInput
          :label="$t('Product/Options.Stock/MaxStock')"
          v-model="BulkEditOptionData.Stock.MaxStock"
          type="number"
          horizontal>
        <template #prepend-content>
          <CIcon name="cil-layers" />
        </template>
      </CInput>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.BulkEdit') }}</h5>
          <CButtonClose @click="BulkEditOptionModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="BulkEditOptionModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="BulkEditSubmit" @click="BulkEditOptions()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="EditOptionValueModel" size="xl" :show.sync="EditOptionValueModel" :centered="true">
      <div class="px-3">
        <CRow class="pb-3 font-weight-bold border-bottom">
          <CCol class="d-flex align-items-center" col="3">{{ $t('Product/Options.Field') }}</CCol>
          <CCol class="d-flex align-items-center" col="3">{{ $t('Product/Options.PropertyName') }}</CCol>
          <CCol class="d-flex align-items-center" col="3">{{ $t('Product/Options.ValueType') }}</CCol>
          <CCol class="d-flex align-items-center" col="3">{{ $t('Product/Options.Value') }}</CCol>
        </CRow>
        <template v-for="(field, index) in OptionField">
          <CRow :key="index" class="py-2 border-bottom">
            <CCol col="2">
              <CInput v-model="field.FieldName" class="mb-0" disabled />
            </CCol>
            <CCol col="3">
              <CRow>
                <CCol col="12" v-for="Option in FilterOptions(field._id)" :key="Option._id">
                  <CInput v-model="Option.OptionName" />
                </CCol>
              </CRow>
            </CCol>
            <CCol col="3">
              <CRow>
                <CCol col="12" v-for="Option in FilterOptions(field._id)" :key="Option._id">
                  <CSelect v-if="Option.Variable" :options="ValueTypeOptions" v-model="Option.Variable.Type" :value.sync="Option.Variable.Type" :placeholder="$t('Global.PleaseSelect')"/>
                </CCol>
              </CRow>
            </CCol>
            <CCol col="3">
              <CRow>
                <CCol col="12" v-for="Option in FilterOptions(field._id)" :key="Option._id">
                  <template v-if="Option.Variable">
                    <CInput v-model="Option.Variable.Value">
                      <template #prepend>
                        <CButton v-if="Option.Variable.Type === 'color'" type="button" color="secondary" @click="ToggleColorSetting(Option)">
                          <CIcon name="cil-color-palette" class="my-0" />
                        </CButton>
                      </template>
                    </CInput>
                    <template v-if="Option.Variable.Type === 'color'">
                      <ColorPicker
                        :acceptLabel="$t('Global.Confirm')"
                        :cancelLabel="$t('Global.Cancel')"
                        :hasResetButton="true"
                        :resetLabel="$t('Global.Reset')"
                        v-if="ColorPalette[Option._id]"
                        v-model="SelectColor[Option._id]"
                        class="position-absolute"
                        @ok="ColorSettingOK(Option)"
                        @cancel="ColorSettingCancel(Option)"
                        @reset="ColorSettingReset(Option)"
                      />
                    </template>
                  </template>
                </CCol>
              </CRow>
            </CCol>
            <CCol col="1">
              <CRow>
                <CCol col="12" v-for="Option in FilterOptions(field._id)" :key="Option._id">
                  <CButton color="danger" class="mb-3 px-2" @click="Options.splice(Options.findIndex(option => option._id === Option._id), 1)">
                    <CIcon name="cil-trash" class="my-0" />
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </template>
      </div>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Product/Options.EditOptionValue') }}</h5>
          <CButtonClose @click="EditOptionValueModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#EditOptionValueSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="EditOptionValueModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="EditOptionValueSubmit" color="success" @click="ChangeOptionName">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </div>
</template>

<script>
import { Photoshop as ColorPicker } from 'vue-color'
export default {
  name: 'ProductOptions',
  props: {
    SKUList: {
      type: Array,
      default () {
        return []
      }
    },
    ExistOptionField: Array,
    ExistOptions: Array,
    ExistOptionData: Array,
    EnableStock: Boolean
  },
  data() {
    return {
      Submit: false,
      CheckAllValue: false,
      FieldGenerateModel: false,
      FieldEditModel: false,
      BulkEditOptionModel: false,
      EditOptionValueModel: false,
      CheckAllKeys: false,
      EditingOptionIndex: null,
      SelectColor: {},
      ColorPalette: {},
      ChooseKeys: [],
      OptionField: [],
      Options: [],
      OptionData: [],
      BulkEditOptionData: {
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        },
        Stock: {
          RealStock: '',
          SafeStock: '',
          MaxStock: ''
        },
      },
      FieldDynamic: [],
      noItemsView: {
        noResults: this.$t('Product/Options.NoOptionsResults'),
        noItems: this.$t('Product/Options.NoOptionsResults')
      },
      DefaultOptionFields: [
          'Checkbox', 'Level', 'CostAmount', 'SuggestAmount', 'ActualAmount', 'SKU', 'MaxStock', 'Action'
      ]
    }
  },
  computed: {
    EnableStockSwitch() {
      return this.EnableStock
    },
    Field() {
      const Field = [
        { key: 'Checkbox', label: '' },
        // { key: 'Level', label: this.$t('Product/Options.Level') },
      ]
      const FieldAmount = [
        { key: 'SuggestAmount', label: this.$t('Product/Options.Amount/Suggest') },
        { key: 'ActualAmount', label: this.$t('Product/Options.Amount/Actual') }
      ]
      const FieldStock = [
        { key: 'SKU', label: this.$t('Product/Options.Stock/SKU') },
        { key: 'MaxStock', label: this.$t('Product/Options.Stock/MaxStock') }
      ]
      const FieldPost = [
        // { key: 'Weight', label: this.$t('Product/Options.Weight') },
        // { key: 'NetWeight', label: this.$t('Product/Options.NetWeight') },
        // { key: 'Length', label: this.$t('Product/Options.Length') },
        // { key: 'Width', label: this.$t('Product/Options.Width') },
        // { key: 'Height', label: this.$t('Product/Options.Height') },
        { key: 'Action', label: '' }
      ]
      const FieldDynamic = this.OptionField.map(data => {
        return {
          key: data._id,
          label: data.FieldName
        }
      })
      if (this.EnableStockSwitch === true) {
        return Field.concat(FieldDynamic, FieldAmount, FieldStock, FieldPost)
      } else {
        return Field.concat(FieldDynamic, FieldAmount, FieldPost)
      }
    },
    ValueTypeOptions () {
      return Object.keys(this.$t('Product/Options.ValueTypeOptions')).map(value => {
        return {
          label: this.$t('Product/Options.ValueTypeOptions.' + value),
          value
        }
      })
    }
  },
  components: {
    ColorPicker,
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  mounted() {
    if (this.ExistOptionField.length > 0) this.OptionField = this.ExistOptionField
    if (this.ExistOptions.length > 0) this.Options = this.ExistOptions
    if (this.ExistOptionData.length > 0) {
      const OptionNames = {}
      this.Options.forEach((option) => {
        OptionNames[option._id] = option.OptionName
      })
      this.ExistOptionData.forEach((data) => {
        const FieldName = Object.assign({}, data.Value)
        Object.keys(data.Value).forEach((fieldID) => {
          FieldName[fieldID] = OptionNames[data.Value[fieldID]]
        })
        this.OptionData.push({
          ...FieldName,
          ...data
        })
      })
      // this.OptionData = this.ExistOptionData.map(data => {
      //   const FieldName = Object.assign({}, data.Value)
      //   Object.keys(data.Value).forEach((fieldID) => {
      //     FieldName[fieldID] = OptionNames[data.Value[fieldID]]
      //   })
      //   return {
      //     ...FieldName,
      //     ...data
      //   }
      // })
    }
  },
  methods: {
    AddField() {
      this.OptionField.push({
        Value: [],
        Variable: {},
        Level: this.OptionField.length + 1,
        FieldName: ""
      })
    },
    RemoveField(index) {
      this.OptionField.splice(index, 1)
    },
    GenerateField() {
      if (this.OptionField.length > 0) {
        return this.$store.dispatch('InnerRequest', {
          url: '/product/options/generate',
          method: 'post',
          data: {
            optionField: this.OptionField,
            saveOptionsSuite: false
          }
        }).then(({data}) => {
          this.OptionField = data.optionField
          this.Options = data.options.map(option => {
            option.Variable = {
              Type: 'text',
              Value: option.OptionName
            }
            return option
          })
          const OptionNames = {}
          data.options.forEach((option) => {
            OptionNames[option._id] = option.OptionName
          })
          this.OptionData = data.optionData.map(optionData => {
            const FieldName = Object.assign({}, optionData.Value)
            Object.keys(optionData.Value).forEach((fieldID) => {
              FieldName[fieldID] = OptionNames[optionData.Value[fieldID]]
            })
            return {
              ...FieldName,
              ...optionData,
              Variable: {
                Weight: 0,
                NetWeight: 0,
                Width: 0,
                Height: 0,
                Length: 0
              }
            }
          })
          this.FieldGenerateModel = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.ProductOption/GenerateSuccess'),
            type: 'success'
          })
          this.HandleEmit()
        }).catch((err) => {
          this.FieldGenerateModel = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Global/ErrorMessage') + err,
            type: 'error'
          })
        })
      } else {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.ProductOption/PleaseField'),
          type: 'warn'
        })
      }
    },
    CheckAll() {
      if (this.CheckAllKeys === true) {
        this.ChooseKeys = this.OptionData.map((data, index) => {
          return index
        })
      } else {
        this.ChooseKeys = []
      }
    },
    BulkEditOptions() {
      this.ChooseKeys.forEach(key => {
        this.OptionData[key].Amount = this.BulkEditOptionData.Amount
        this.OptionData[key].Stock = this.BulkEditOptionData.Stock
      })
      this.BulkEditOptionModel = false
      this.BulkEditOptionData = {
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        },
        Stock: {
          RealStock: '',
          SafeStock: '',
          MaxStock: ''
        },
      }
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Info'),
        text: this.$t('Message.ProductOption/ChangeOptionData'),
        type: 'info',
        duration: 10000
      })
      this.HandleEmit()
    },
    EditOptions(index) {
      if (this.EditingOptionIndex === index) {
        //修改完成
        this.EditingOptionIndex = null
      } else {
        //開始修改
        this.EditingOptionIndex = index
      }
      this.HandleEmit()
    },
    RemoveOptions(index) {
      this.OptionData.splice(index, 1)
      this.HandleEmit()
    },
    ResetOptions() {
      this.ChooseKeys = []
      this.OptionData = []
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Info'),
        text: this.$t('Message.ProductOption/ResetOptionData'),
        type: 'info',
        duration: 10000
      })
      this.HandleEmit()
    },
    AddTag (newTag, index) {
      this.OptionField[index].Value.push(newTag)
    },
    HandleEmit() {
      this.$emit('update:ExistOptionField', this.OptionField)
      this.$emit('update:ExistOptions', this.Options)
      this.$emit('update:ExistOptionData', this.OptionData)
    },
    FilterOptions(FieldID) {
      return this.Options.filter(Option => Option.FieldID === FieldID)
    },
    ChangeOptionName() {
      this.OptionField.forEach((Field, Key) => {
        // OptionField Change
        this.OptionField[Key].Value = this.Options.filter(Option => Field._id === Option.FieldID).map((Option) => Option.OptionName)
        // OptionData Change
        this.OptionData.forEach((Data, Index) => {
          this.OptionData[Index][Field._id] = this.Options.filter(Option => this.OptionData[Index]['Value'][Field._id] === Option._id)[0]['OptionName']
        })
      })
      this.EditOptionValueModel = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Info'),
        text: this.$t('Message.ProductOption/ChangeOptionName'),
        type: 'info',
        duration: 10000
      })
      this.HandleEmit()
    },
    ToggleColorSetting (item) {
      this.$set(this.SelectColor, item._id, {
        hex: item.Variable.Value.includes('#') && item.Variable.Value.length === 7 ? item.Variable.Value : ''
      })
      this.$set(this.ColorPalette, item._id, !this.ColorPalette[item._id])
    },
    ColorSettingOK (item) {
      const index = this.Options.findIndex(option => option._id === item._id)
      this.Options[index].Variable.Value = this.SelectColor[item._id].hex
      this.$set(this.ColorPalette, item._id, !this.ColorPalette[item._id])
    },
    ColorSettingCancel (item) {
      this.$set(this.ColorPalette, item._id, !this.ColorPalette[item._id])
    },
    ColorSettingReset (item) {
      const index = this.Options.findIndex(option => option._id === item._id)
      this.Options[index].Variable.Value = ''
      this.$set(this.ColorPalette, item._id, !this.ColorPalette[item._id])
    }
  }
}
</script>

<style lang="scss">
#ProductOptions {
  thead {
    th:first-child {
      text-align: center;
    }
  }
  tbody {
    td:first-child {
      width: 60px;
      min-width: initial;
      vertical-align: middle;
    }
    td {
      min-width: 100px;
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .vc-photoshop {
    z-index: 1000;
  }
}
</style>
